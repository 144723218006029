import { useState, useContext } from "react";
import Page from "./Page";
import {FirebaseContext} from "../index";
import { Button, TextField, Typography, Container, Box, CircularProgress, Snackbar } from '@mui/material';
import { httpsCallable } from "firebase/functions";
import {
    getFunctions,
    // connectFunctionsEmulator
} from "firebase/functions";
import {primaryColor, whiteColor} from "../palette";

const ContactPage = () => {

    const app = useContext(FirebaseContext);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!isValidEmail(email)) {
            setErrorMessage("Invalid email");
        } else {
            setLoading(true);
            console.log(name, email, message);
            try {
                const funcs = getFunctions(app);
                const sendMail = httpsCallable(funcs, 'sendMail');
                // connectFunctionsEmulator(funcs, "localhost", 5001);
                await sendMail({name: name, email: email, message: message});
                setOpen(true);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        }
    };

    return (
        <Page id="contactPage">
            <Box sx={{
                backgroundColor: primaryColor,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "5rem",
            }}>
                <Typography sx={{
                    color: whiteColor,
                    fontSize: "2rem",
                    fontWeight: 600,
                }}>
                    Contact Us
                </Typography>
            </Box>
            <Container maxWidth="sm" sx={{padding: "3rem"}}>
                <form onSubmit={onSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        error={!!errorMessage}
                        helperText={errorMessage}
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        id="message"
                        label="Message"
                        name="message"
                        autoComplete="message"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                    />
                    {loading ? <CircularProgress /> : <Button
                        className="g-recaptcha"
                        data-sitekey="6LcjL_snAAAAAMB-veCd3miTGqicw4ENTq4JOQPI"
                        data-callback="onSubmit"

                        variant="outlined"
                        type="submit"
                        sx={{
                            color: primaryColor,
                            border: `3px solid ${primaryColor}`,
                            padding: ".5rem 1rem",
                            alignItems: "center",
                            fontSize: "22px",
                            letterSpacing: ".1rem",
                            borderRadius: "10px",
                            fontWeight: 700,
                            marginTop: "1rem",
                            "&:hover": {
                                border: `3px solid ${primaryColor}`,
                                backgroundColor: primaryColor,
                                color: whiteColor,
                            },
                        }}
                    >
                        Submit
                    </Button>}
                </form>
            </Container>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                message="Message Sent!"
            />
        </Page>
    );
};

export default ContactPage;
