import Page from "./Page";
import {
    Box,
    Grid,
    // Button,
    Typography
} from "@mui/material";
// import {Link} from "react-router-dom";
import StyledButton from "./StyledButton";
import {
    primaryColor,
    whiteColor,
    blackColor,
    darkGrayColor,
    lightGrayColor
} from "../palette";

const HomePage = () => {
    const services = [
        "DRY CLEANING",
        "WASH & FOLD",
        "REPAIRS",
        "LEATHER CLEANING",
        "LAUNDER & PRESS",
        "HANG DRY",
        "HOUSEHOLD ITEMS",
        "WEDDING GOWNS CLEANING & PRESERVATION"
    ];

    return (
        <Page id="homePage">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "25rem",
                    padding: "3rem",
                    backgroundImage: "url('tdc_home_hero.webp')",
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        color: whiteColor,
                        textShadow: `2px 2px 4px ${blackColor}`,
                        fontSize: "3rem",
                        fontWeight: 500,
                        letterSpacing: ".1rem",
                        textAlign: "center",
                    }}
                >
                    Free Pick Up &<br />Free Delivery
                </Typography>
                <Typography
                    variant="h2"
                    sx={{
                        marginTop: "1.5rem",
                        color: whiteColor,
                        textShadow: `2px 2px 4px ${blackColor}`,
                        fontSize: "24px",
                        fontWeight: 500,
                        letterSpacing: ".1rem",
                        textAlign: "center",
                    }}
                >
                    You’ve got better things to do…<br />We’ll handle the laundry.
                </Typography>
                <Grid container justifyContent={"center"} sx={{my: "5rem"}} spacing={2}>
                    <Grid item>
                        <StyledButton
                            text="ORDER NOW"
                            target="_blank"
                            rel="noopener"
                            link="https://triangledrycleaners.smrtapp.com/customer/"
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5rem",
                    backgroundColor: whiteColor
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        color: darkGrayColor,
                        fontSize: "30px",
                        fontWeight: 500,
                        letterSpacing: ".1rem",
                    }}
                >
                    CLEAN CLOTHES
                </Typography>
                <Typography
                    variant="h3"
                    sx={{
                        marginTop: "1.5rem",
                        color: darkGrayColor,
                        fontSize: "20px",
                        fontWeight: 700,
                    }}
                >
                    AS EASY AS...
                </Typography>
                <Grid container justifyContent={"center"} alignItems={"center"} sx={{marginTop: "3rem"}} spacing={4}>
                    <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"} xs={12} md={3}>
                        <Grid item>
                            <img
                                src="tdc_place_order.png"
                                alt="Place your order"
                                height="150px"
                                width="150px"
                                style={{borderRadius: "50%"}}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h3"
                                sx={{
                                    marginTop: "1.5rem",
                                    color: darkGrayColor,
                                    fontSize: "24px",
                                }}
                            >
                                1. PLACE YOUR ORDER
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h3"
                                sx={{
                                    marginTop: "1.5rem",
                                    color: darkGrayColor,
                                    fontSize: "20px",
                                }}
                            >
                                Place your order by phone or online & schedule a time for us to pick-up your clothes.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"} xs={12} md={3}>
                        <Grid item>
                            <img
                                src="we_pick_up.png"
                                alt="We Pick Up"
                                height="150px"
                                width="150px"
                                style={{borderRadius: "50%"}}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h3"
                                sx={{
                                    marginTop: "1.5rem",
                                    color: darkGrayColor,
                                    fontSize: "24px",
                                }}
                            >
                                2. WE PICK-UP
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h3"
                                sx={{
                                    marginTop: "1.5rem",
                                    color: darkGrayColor,
                                    fontSize: "20px",
                                }}
                            >
                                One of our friendly drivers will be over in a jiffy to collect your dirty clothes. At home, at work, wherever you like.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"} xs={12} md={3}>
                        <Grid item>
                            <img
                                src="delivery.png"
                                alt="Delivery"
                                height="150px"
                                width="150px"
                                style={{borderRadius: "50%"}}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h3"
                                sx={{
                                    marginTop: "1.5rem",
                                    color: darkGrayColor,
                                    fontSize: "24px",
                                }}
                            >
                                3. DELIVERY
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h3"
                                sx={{
                                    marginTop: "1.5rem",
                                    color: darkGrayColor,
                                    fontSize: "20px",
                                }}
                            >
                                Voila! Your freshly cleaned clothes delivered back to you within 48 hours.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "5rem",
                    backgroundColor: lightGrayColor
                }}
            >
                <Grid container item direction={"column"} xs={12} md={6} spacing={2}>
                    <Grid item>
                        <Typography
                            variant="h2"
                            sx={{
                                color: darkGrayColor,
                                fontSize: "30px",
                                fontWeight: 500,
                                letterSpacing: ".2rem",
                            }}
                        >
                            WHY USE US?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body"
                            sx={{
                                color: darkGrayColor,
                                fontSize: "20px",
                            }}
                        >
                            First off, we know how busy you are, that’s why we offer door-to-door delivery anywhere, anytime. We’re commited to high quality service so we use the latest in eco-friendly dry cleaning technology. Only the best for your garments! Turn your laundry-day into a breeze.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body"
                            sx={{
                                color: darkGrayColor,
                                fontSize: "20px",
                            }}
                        >
                            <ul>
                                <li>No more missions to the laundromat & remembering to pickup the dry cleaning</li>
                                <li>Clean clothes without the hassle, we work with your busy schedule</li>
                                <li>High quality customer service everytime</li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item>
                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    component={Link}*/}
                        {/*    to={"/about-us"}*/}
                        {/*    sx={{*/}
                        {/*        color: primaryColor,*/}
                        {/*        border: `3px solid ${primaryColor}`,*/}
                        {/*        padding: ".5rem 1rem",*/}
                        {/*        fontSize: "20px",*/}
                        {/*        letterSpacing: ".1rem",*/}
                        {/*        borderRadius: "10px",*/}
                        {/*        fontWeight: 700,*/}
                        {/*        "&:hover": {*/}
                        {/*            border: `3px solid ${primaryColor}`,*/}
                        {/*            backgroundColor: primaryColor,*/}
                        {/*            color: secondaryColor,*/}
                        {/*        },*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    LEARN MORE*/}
                        {/*</Button>*/}
                    </Grid>
                </Grid>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "3rem",
                    backgroundColor: whiteColor
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        color: darkGrayColor,
                        fontSize: "30px",
                        fontWeight: 500,
                        letterSpacing: ".1rem",
                    }}
                >
                    Services
                </Typography>
                <Typography
                    variant="h3"
                    sx={{
                        color: darkGrayColor,
                        fontSize: "20px",
                        padding: "1rem",
                    }}
                >
                    HIGH QUALITY CLOTHING CARE FOR EVERYTHING IN YOUR CLOSET
                </Typography>

                <Grid container sx={{ marginTop: '1rem' }} spacing={3}>
                    {services.map((service, i) =>
                        <Grid container item key={i} xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Grid item xs={2} md={2}>
                                <img
                                    src="check_mark.png"
                                    alt="checkmark"
                                    height="48px"
                                    width="50px"
                                />
                            </Grid>
                            <Grid item xs={10} md={4}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: darkGrayColor,
                                        fontSize: "24px",
                                        fontWeight: 500,
                                    }}
                                >
                                    {service}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {/*<Button*/}
                {/*    variant="outlined"*/}
                {/*    component={Link}*/}
                {/*    to={"/about-us"}*/}
                {/*    sx={{*/}
                {/*        color: primaryColor,*/}
                {/*        border: `3px solid ${primaryColor}`,*/}
                {/*        padding: ".5rem 1rem",*/}
                {/*        fontSize: "22px",*/}
                {/*        letterSpacing: ".1rem",*/}
                {/*        borderRadius: "10px",*/}
                {/*        fontWeight: 700,*/}
                {/*        "&:hover": {*/}
                {/*            border: `3px solid ${primaryColor}`,*/}
                {/*            backgroundColor: primaryColor,*/}
                {/*            color: secondaryColor,*/}
                {/*        },*/}
                {/*    }}*/}
                {/*>*/}
                {/*    LEARN MORE*/}
                {/*</Button>*/}
            </Box>

            <Box>
                <img
                    src="tdc_home_bg.webp"
                    alt="Clothing Rainbow"
                    width="100%"
                    style={{ display: 'block', verticalAlign: 'top' }}
                />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: primaryColor,
                    padding: "5rem",
                    borderRadius: "0",
                }}
            >
                <Grid container direction={"column"} spacing={2} alignItems={"center"}>
                    <Grid item>
                        <Typography
                            variant="h2"
                            sx={{
                                color: whiteColor,
                                fontSize: "30px",
                                fontWeight: 500,
                                letterSpacing: ".2rem",
                            }}
                        >
                            OUR SERVICES
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="h3"
                            sx={{
                                color: whiteColor,
                                fontSize: "20px",
                                padding: "1rem",
                            }}
                        >
                            CLEAN CLOTHES DELIVERED WITHOUT BREAKING THE BANK
                        </Typography>
                    </Grid>

                    <Grid container direction={"row"} spacing={6} alignItems={"center"} justifyContent={"center"}>
                        <Grid item>
                            <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                                <Grid item>
                                    <img
                                        src="dc_blouse.png"
                                        alt="Blouse"
                                        height="148px"
                                        width="129px"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body"
                                        sx={{
                                            marginTop: "1.5rem",
                                            color: whiteColor,
                                            textAlign: "center",
                                            fontWeight: 700,
                                        }}
                                    >
                                        DRY CLEANED<br />BLOUSES
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                                <Grid item>
                                    <img
                                        src="dresses.png"
                                        alt="Dress"
                                        height="148px"
                                        width="129px"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body"
                                        sx={{
                                            marginTop: "1.5rem",
                                            color: whiteColor,
                                            textAlign: "center",
                                            fontWeight: 700,
                                        }}
                                    >
                                        DRESSES
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                                <Grid item>
                                    <img
                                        src="laundered_shirt.png"
                                        alt="Laundered Shirt"
                                        height="148px"
                                        width="129px"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body"
                                        sx={{
                                            marginTop: "1.5rem",
                                            color: whiteColor,
                                            textAlign: "center",
                                            fontWeight: 700,
                                        }}
                                    >
                                        LAUNDERED SHIRTS
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                                <Grid item>
                                    <img
                                        src="2pc_suit.png"
                                        alt="2 Piece Suit"
                                        height="148px"
                                        width="129px"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body"
                                        sx={{
                                            marginTop: "1.5rem",
                                            color: whiteColor,
                                            textAlign: "center",
                                            fontWeight: 700,
                                        }}
                                    >
                                        2 PIECE SUITS
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                                <Grid item>
                                    <img
                                        src="wash_fold.png"
                                        alt="Wash and Fold"
                                        height="148px"
                                        width="129px"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body"
                                        sx={{
                                            marginTop: "1.5rem",
                                            color: whiteColor,
                                            textAlign: "center",
                                            fontWeight: 700,
                                        }}
                                    >
                                        WASH &amp; FOLD
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
};

export default HomePage;
