import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import router from "./router";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDVyXAbmmzjxG_9nsq1UQzZxml1RxWKLXo",
    authDomain: "triangledrycleaner.firebaseapp.com",
    projectId: "triangledrycleaner",
    storageBucket: "triangledrycleaner.appspot.com",
    messagingSenderId: "698800621288",
    appId: "1:698800621288:web:5bc6394ddaf4a3205d2b5d",
    measurementId: "G-WZS59XB7WH"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const eventLogger = (eventName, eventParams) => {
    if (eventParams) {
        logEvent(analytics, eventName, eventParams);
    } else {
        logEvent(analytics, eventName);
    }
};

export const FirebaseContext = createContext(app);
export const EventLoggerContext = createContext(eventLogger);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
