import ContactPage from "./components/ContactPage";
import HomePage from "./components/HomePage";
import ServicesPage from "./components/ServicesPage";
// import AboutPage from "./components/AboutPage";

const PAGES = {
    "Home": {
        path: "/",
        element: <HomePage />,
    },
    // "About Us": {
    //     path: "/about-us",
    //     element: <AboutPage />,
    // },
    "Our Services": {
        path: "/our-services",
        element: <ServicesPage />,
    },
    "Contact Us": {
        path: "/contact-us",
        element: <ContactPage />,
    },
    "Login / Signup": {
        path: "https://triangledrycleaners.smrtapp.com/customer/",
        element: undefined,
    },
};

export default PAGES;