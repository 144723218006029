import {useContext, useState} from "react";
import Page from "./Page";
import {Link} from "react-router-dom";
import { Box, Card, Typography, List, ListItemText, ListItemButton, Divider, Collapse, Button } from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {blackColor, darkGrayColor, primaryColor, whiteColor} from "../palette";
import {EventLoggerContext} from "../index";

const ServicesPage = () => {
    const eventLogger = useContext(EventLoggerContext);

    const services = [
        "ECO-FRIENDLY DRY CLEANING",
        "LAUNDERED & PRESSED DRESS SHIRTS",
        "WASH & FOLD LAUNDRY",
        "WEDDING GOWN CLEANING & PRESERVATION",
    ];
    const dcItems = [
        "SHIRTS / BLOUSES",
        "POLO / GOLF SHIRTS",
        "SWEATERS",
        "PANTS / SKIRTS",
        "DRESSES",
        "BLAZERS / JACKETS",
        "2 PIECE SUITS",
        "TIES",
        "WINTER COATS",
        "COMFORTERS",
        "DUVETS",
        "DRAPES",
        "SHEETS",
        "PILLOW CASES",
        "TABLE CLOTHS",
    ];

    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(!open);

    return (
        <Page id="servicesPage">
            <Box sx={{
                backgroundColor: primaryColor,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "5rem",
            }}>
                <Typography sx={{
                    color: whiteColor,
                    fontSize: "2rem",
                    fontWeight: 600,
                }}>
                    Our Services
                </Typography>
            </Box>
            <Box sx={{
                backgroundColor: whiteColor,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "5rem",
            }}>
                <List sx={{
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    {services.map((service, i) => (
                        <li key={i}>
                            {i===0 && <ListItemButton onClick={handleClick}>
                                <ListItemText
                                    primary={service}
                                    sx={{padding: "1rem"}}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontSize: "1.5rem",
                                            fontWeight: 500,
                                        }
                                    }}
                                />
                                {open ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>}
                            {i!==0 && <ListItemText
                                primary={service}
                                sx={{padding: "1rem"}}
                                primaryTypographyProps={{
                                    sx: {
                                        fontSize: "1.5rem",
                                        fontWeight: 500,
                                        paddingLeft: "1rem",
                                    }
                                }}
                            />}
                            {i===0 && <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding sx={{
                                    paddingLeft: "3rem",
                                }}>
                                    {dcItems.map((item, i) => (
                                        <ListItemText
                                            primary={item}
                                            key={i}
                                            sx={{padding: "0.25rem"}}
                                            primaryTypographyProps={{
                                                sx: {
                                                    fontSize: "1.25rem",
                                                }
                                            }}
                                        />
                                    ))}
                                </List>
                            </Collapse>}

                            <Divider
                                variant="inset"
                                // component="li"
                                sx={{
                                    width: "100%",
                                    margin: "auto",
                                }}
                            />
                        </li>
                    ))}
                </List>
            </Box>
            <Card sx={{
                backgroundColor: whiteColor,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "4rem",
                border: `1px solid ${darkGrayColor}`,
                width: "50%",
                margin: "auto",
                boxShadow: `0 0 3px ${darkGrayColor}`,
            }}>
                <Typography sx={{ fontSize: "1.5rem", fontWeight: 600 }}>
                    <span style={{ color: 'red' }}>*</span>
                    Please Note:
                    <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Typography sx={{
                    color: blackColor,
                    fontSize: "1.25rem",
                    fontWeight: 500,
                    textAlign: "center",
                    padding: "1rem",
                }}>
                    Surcharges will apply for folding, trims, delicate materials (ie. silk, satins and lace), skins (ie. leather, suede and fur), pleating, linings, buttons and accessory removal and reattachment, difficult staining (ie. blood, wine and ink), smoke damage, extensive soiling and other contamination.
                </Typography>
                <Typography
                    sx={{
                        color: blackColor,
                        fontSize: "1.25rem",
                        fontWeight: 500,
                        textAlign: "center",
                        padding: "1rem",
                    }}
                >
                    Cleaning occurs during business hours Monday to Friday. Pickups and deliveries can be scheduled.
                </Typography>
                <Typography
                    sx={{
                        color: blackColor,
                        fontSize: "1.25rem",
                        fontWeight: 500,
                        textAlign: "center",
                        padding: "1rem",
                    }}
                >
                    We require 2 business days minimum for our regular service which includes free pickup/delivery.
                </Typography>
            </Card>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '3rem 0 3rem 0',
                    width: '100vw'
                }}
            >
                <Button
                    variant="outlined"
                    component={Link}
                    target="_blank"
                    rel="noopener"
                    to="https://triangledrycleaners.smrtapp.com/customer/"
                    onClick={() => eventLogger("open_customer_portal")}
                    sx={{
                        color: primaryColor,
                        border: `3px solid ${primaryColor}`,
                        padding: ".5rem 1rem",
                        alignItems: "center",
                        fontSize: "22px",
                        letterSpacing: ".1rem",
                        borderRadius: "10px",
                        fontWeight: 700,
                        "&:hover": {
                            border: `3px solid ${primaryColor}`,
                            backgroundColor: primaryColor,
                            color: whiteColor,
                        },
                    }}
                >
                    ORDER NOW
                </Button>
            </Box>
        </Page>
    );
};

export default ServicesPage;
