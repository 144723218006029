import NavBar from "./NavBar";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

const Page = ({children, ...props}) => {
    return (
        <div className="page" {...props}>
            <ScrollToTop />
            <NavBar />
            {children}
            <Footer />
        </div>
    );
};

export default Page;
