import {useContext} from "react";
import {EventLoggerContext} from "../index";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {primaryColor, whiteColor} from "../palette";

const StyledButton = ({text, link, ...props}) => {
    const eventLogger = useContext(EventLoggerContext);
    return (
        <Button
            variant="outlined"
            {...props}
            component={Link}
            to={link}
            onClick={() => eventLogger("open_customer_portal")}
            sx={{
                color: whiteColor,
                border: `3px solid ${whiteColor}`,
                padding: "1rem 3rem",
                fontSize: "24px",
                letterSpacing: ".1rem",
                borderRadius: "10px",
                fontWeight: 700,
                "&:hover": {
                    border: `3px solid ${whiteColor}`,
                    backgroundColor: whiteColor,
                    color: primaryColor,
                },
            }}
        >
            {text}
        </Button>
    );
}

export default StyledButton;