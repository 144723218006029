import {useContext} from "react";
import {EventLoggerContext} from "../index";
import {Box, Grid, Typography} from "@mui/material";
import {Phone, Email} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {grayColor, whiteColor} from "../palette";
import PAGES from "../pages";

const Footer = () => {
    const eventLogger = useContext(EventLoggerContext);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                padding: "3rem",
                backgroundColor: grayColor,
            }}
            component="footer"
        >
            <Grid container spacing={2}>
                <Grid container item direction="column" alignItems="center"
                      xs={12} md={3} spacing={2}
                >
                    <Grid item>
                        <img
                            src="tr_logo.webp"
                            alt="Triangle Dry Cleaners White Logo"
                            height={75}
                        />
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body"
                            sx={{
                                color: whiteColor,
                                fontSize: "16px",
                                fontWeight: 500,
                                letterSpacing: ".1rem",
                            }}
                        >
                            Experience Effortless Dry Cleaning with Triangle Dry Cleaners - Proudly Serving the Wake
                            Forest Area Since 2005, Now Under the Dedicated Leadership of a US Army Veteran.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item direction="column" xs={12} md={3} spacing={2} alignItems="flex-start">
                    <Grid item>
                        <Typography
                            variant="h2"
                            sx={{
                                color: whiteColor,
                                fontSize: "20px",
                                fontWeight: 600,
                                letterSpacing: ".1rem",
                            }}
                        >
                            Contact Us
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body"
                            component={Link}
                            to={"https://goo.gl/maps/puJvG561aKfZZFPP9"}
                            target={"_blank"}
                            sx={{
                                color: whiteColor,
                                textDecoration: "none",
                                fontSize: "16px",
                            }}
                        >
                            Triangle Dry Cleaners<br/>
                            3325 Rogers Rd.<br/>
                            (Suite 118) <br/>
                            Wake Forest, NC 27587<br/>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body"
                            component={Link}
                            to={"tel:+19194532800"}
                            sx={{
                                color: whiteColor,
                                textDecoration: "none",
                                fontSize: "16px"
                            }}
                        >
                            <Phone sx={{
                                fontSize: "16px",
                                padding: "0 .5rem 0 0"
                            }}/>
                            (919) 453 2800
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body"
                            component={Link}
                            to={"mailto:contact@triangledrycleaners.com"}
                            sx={{
                                color: whiteColor,
                                textDecoration: "none",
                                fontSize: "16px"
                            }}
                        >
                            <Email sx={{
                                fontSize: "16px",
                                padding: "0 .5rem 0 0"
                            }}/>
                            contact@triangledrycleaners.com
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item direction="column" alignItems="flex-start"
                      xs={12} md={2} spacing={2}
                >
                    <Grid item>
                        <Typography
                            variant="h2"
                            sx={{
                                color: whiteColor,
                                fontSize: "20px",
                                fontWeight: 600,
                                letterSpacing: ".1rem",
                            }}
                        >
                            Hours of Operation
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body"
                            sx={{
                                color: whiteColor,
                                fontSize: "16px",
                                fontWeight: 500,
                            }}
                        >
                            Mon - Fri: 7AM - 7PM<br/>
                            Sat: 9AM - 4PM<br/>
                            Sun: Closed<br/>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item direction="column" alignItems="center"
                      xs={12} md={2} spacing={2}
                >
                    <Grid item>
                        <Typography
                            variant="h2"
                            sx={{
                                color: whiteColor,
                                fontSize: "20px",
                                fontWeight: 600,
                                letterSpacing: ".1rem",
                            }}
                        >
                            Pages
                        </Typography>
                    </Grid>
                    {Object.entries(PAGES).map(([page, route], index) => (
                        <Grid item key={index}>
                            <Typography
                                variant="body"
                                component={Link}
                                target={route.path.startsWith("http") ? "_blank" : ""}
                                onClick={() => eventLogger("open_customer_portal")}
                                to={route.path}
                                sx={{
                                    color: whiteColor,
                                    textDecoration: "none",
                                    fontSize: "16px"
                                }}
                            >
                                {page}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Footer;