import {useState, useContext} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, Link } from "react-router-dom";
import {grayColor, primaryColor, whiteColor} from "../palette";
import PAGES from "../pages";
import {EventLoggerContext} from "../index";

const NavBar = () => {
    const eventLogger = useContext(EventLoggerContext);
    const location = useLocation();
    const [anchorElNav, setAnchorElNav] = useState();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (logEvent = false) => {
        if (logEvent) {
            eventLogger("open_customer_portal");
        }
        setAnchorElNav(null);
    };

    return (
        <AppBar position="sticky" style={{backgroundColor: whiteColor}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters style={{justifyContent: "center", alignItems: "center"}}>
                    <Link to={"/"}>
                        <img
                            src="tr_logo.webp"
                            alt="Triangle Dry Cleaners Logo"
                            height="153px"
                            width="198px"
                            style={{padding: "5px"}}
                        />
                    </Link>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color={primaryColor}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {Object.entries(PAGES).map(([page, route]) => (
                                <MenuItem key={page} onClick={() => handleCloseNavMenu(route.startsWith("http"))}>
                                    <Typography textAlign="center" component={Link} to={route} style={{textDecoration: 'none'}}>{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, marginLeft: "5vw", display: { xs: "none", md: "flex" } }}>
                        {Object.entries(PAGES).map(([page, route]) => (
                            <Button
                                key={page}
                                component={Link}
                                target={route.path.startsWith("http") ? "_blank" : ""}
                                to={route.path}
                                onClick={() => handleCloseNavMenu(route.path.startsWith("http"))}
                                sx={{
                                    my: 2,
                                    color: location.pathname === route.path ? primaryColor : grayColor,
                                    display: "block",
                                    fontWeight: 800,
                                    fontSize: "1.1rem",
                                    marginLeft: "2vw",
                                    "&:hover": {
                                        color: primaryColor,
                                        backgroundColor: whiteColor,
                                    },
                                    "&:active": {
                                        color: primaryColor,
                                        backgroundColor: whiteColor,
                                    },
                                }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavBar;